module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"patrick-carroll-design","short_name":"pcd","start_url":"/","background_color":"#071c29","display":"minimal-ui","icon":"src/images/pcd_logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2a327314d18053789a4bad00eb84c593"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
